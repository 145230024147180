import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationStoreService } from 'ngx-authentication';
import { UserPermissionsStoreService } from 'ngx-common-solution';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  distinctUntilChanged,
  of,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { NotificationGetDto } from 'src/dto/GetDtos/notification-get-dto';
import { UserGetDto } from 'src/dto/GetDtos/user-get-dto';
import { UserTeamGetDto } from 'src/dto/GetDtos/user-team-get-dto';
import { TeamService } from 'src/services/http/team.service';
import { UserService } from 'src/services/http/user.service';

interface UserTeamStorage {
  teamId: number;
  expiration: number;
}
@Injectable({
  providedIn: 'root',
})
export class CurrentUserStoreService implements OnDestroy {
  private onDestroy$ = new Subject();
  public static CURRENT_TEAM_ID_KEY: string = 'currentTeamId';

  private _user$ = new BehaviorSubject<UserGetDto | null>(null);
  user$ = this._user$.pipe(shareReplay(1));

  private _currentTeam$ = new ReplaySubject<UserTeamGetDto | undefined>();
  currentTeam$ = this._currentTeam$.pipe(distinctUntilChanged());

  private _userTeams$ = new ReplaySubject<UserTeamGetDto[]>();
  userTeams$ = this._userTeams$.pipe(distinctUntilChanged());

  private _notifications$ = new ReplaySubject<NotificationGetDto[]>();
  notifications: NotificationGetDto[] = [];
  notifications$ = this._notifications$.pipe(
    distinctUntilChanged(),
    tap((notifications) => (this.notifications = notifications)),
  );

  private _settings$ = new BehaviorSubject<{ key: number; value: string }[]>(
    [],
  );
  settings$ = this._settings$.pipe(shareReplay(1));

  // private _permissions$ = new BehaviorSubject<EnumPermissions[]>([]);
  // permissions$ = this._permissions$.asObservable();
  constructor(
    private userService: UserService,
    private teamService: TeamService,
    authService: AuthenticationStoreService,
    private userPermissionsStore: UserPermissionsStoreService,
  ) {
    const isAuth$ = authService.isAuthenticated$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
    );
    // isAuth$
    //   .pipe(
    //     switchMap((isAuth) => (isAuth ? userService.getCurrent() : of(null))),
    //   )
    //   .subscribe((user) => this.setUser(user));
    isAuth$
      .pipe(
        switchMap((isAuth) =>
          isAuth ? userService.getCurrentPermissions() : of([]),
        ),
      )
      .subscribe((permissions) =>
        userPermissionsStore.setUserPermissions(permissions),
      );
    isAuth$
      .pipe(
        switchMap((isAuth) =>
          isAuth ? userService.getCurrentTeams() : of([]),
        ),
      )
      .subscribe((userTeams) => this.setUserTeams(userTeams));
    isAuth$.subscribe((isAuth) => {
      if (!isAuth) {
        this.saveCurrentTeamId(undefined);
      }
    });
    isAuth$
      .pipe(
        switchMap((isAuth) =>
          timer(0, 300000).pipe(
            switchMap((r) =>
              isAuth ? userService.getCurrentNotifications() : of([]),
            ),
          ),
        ),
      )
      .subscribe((notifications) => this.setNotifications(notifications));
    this.userTeams$.subscribe((userTeams) => {
      let currentTeam = userTeams.find(
        (t) => t.teamId == this.getCurrentTeamId(),
      );
      if (!currentTeam) {
        currentTeam = userTeams.find((t) => t.isDefault);
      }
      this.setTeam(currentTeam, false);
    });
    combineLatest([isAuth$, this.currentTeam$])
      .pipe(
        switchMap(([isAuth, currentTeam]) =>
          isAuth ? userService.getCurrentSettings(currentTeam?.teamId) : of([]),
        ),
      )
      .subscribe((settings) => this.setSettings(settings));
  }
  setUser(user: UserGetDto | null) {
    this._user$.next(user);
  }
  setTeam(team: UserTeamGetDto | undefined, saveInLocalStorage = true) {
    this._currentTeam$.next(team);
    if (saveInLocalStorage) this.saveCurrentTeamId(team?.teamId);
  }
  setDefaultTeam(userTeam: UserTeamGetDto) {
    return this.teamService
      .addUser(userTeam.teamId, userTeam.userId, !userTeam.isDefault)
      .pipe(
        switchMap((r) => this.userTeams$),
        take(1),
        tap((userTeams) => {
          if (userTeams.find((ut) => ut.teamId == userTeam.teamId)) {
            this.setUserTeams(
              userTeams.map((t) =>
                t.teamId == userTeam.teamId
                  ? { ...t, ...userTeam, isDefault: !userTeam.isDefault }
                  : { ...t, isDefault: false },
              ),
            );
          }
        }),
      );
  }
  setUserTeams(userTeams: UserTeamGetDto[]) {
    this._userTeams$.next(userTeams);
  }
  setNotifications(notifications: NotificationGetDto[]) {
    this._notifications$.next(notifications);
  }
  updateNotificationAsRead(notificationId: number) {
    this._notifications$.next(
      this.notifications.filter(
        (notification) => notification.id != notificationId,
      ),
    );
  }
  setSettings(settings: { key: number; value: string }[]) {
    this._settings$.next(settings);
  }
  saveCurrentTeamId(teamId: number | undefined) {
    if (teamId) {
      const userTeamStorage: UserTeamStorage = {
        teamId: teamId,
        expiration: new Date().getTime() + 1000 * 60 * 60 * 8,
      };
      localStorage.setItem(
        CurrentUserStoreService.CURRENT_TEAM_ID_KEY,
        JSON.stringify(userTeamStorage),
      );
    } else localStorage.removeItem(CurrentUserStoreService.CURRENT_TEAM_ID_KEY);
  }
  getCurrentTeamId(): number | null {
    const userTeamStorageString = localStorage.getItem(
      CurrentUserStoreService.CURRENT_TEAM_ID_KEY,
    );
    if (!userTeamStorageString) {
      return null;
    }
    try {
      const userTeamStorage = JSON.parse(
        userTeamStorageString,
      ) as UserTeamStorage;
      if (userTeamStorage.expiration < new Date().getTime()) {
        this.saveCurrentTeamId(undefined);
        return null;
      }
      return userTeamStorage.teamId;
    } catch (e) {
      this.saveCurrentTeamId(undefined);
      return null;
    }
  }
  refreshPermissions() {
    this.userService
      .getCurrentPermissions()
      .subscribe((permissions) =>
        this.userPermissionsStore.setUserPermissions(permissions),
      );
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
